import { Box, Container, LinearProgress } from '@material-ui/core'
import { ContainerPage, Summary, TitlePage } from 'components'
import {
  CardBreak,
  CardInfo,
  CardInfoToggle,
  CardRoute,
  ModalBreak,
  SeparatorList,
} from 'components/pages/Dashboard'
import { ModalRoute } from 'components/pages/Dashboard/ModalRoute'
import { useBreak } from 'hooks/useBreak'
import { useConferenceChuchu } from 'hooks/useConferenceChuchu'
import { useDashboard } from 'hooks/useDashboard'
import { IRouteDashboard } from 'interfaces/IDashboard'
import { useState, useEffect } from 'react'

import { useStyles } from './styles'

const Dashboard = () => {
  const classes = useStyles()
  const [modalBreak, setModalBreak] = useState(false)
  const { dashboardData, refreshDashboard, loading } = useDashboard()
  const { recoveryConference } = useConferenceChuchu()
  const { breaks } = useBreak()
  const [openModalRoute, setOpenModalRoute] = useState({
    open: false,
    routeData: {} as IRouteDashboard,
  })
  const {
    data,
    completed_orders,
    total_orders,
    total_volume,
    total_ifood_orders,
    total_frexco_orders,
    processed_volume,
    lastUpdate,
    orders_in_progress,
    average_idle_time,
    tons_per_hour,
  } = dashboardData

  const pendentOrders = total_orders - orders_in_progress - completed_orders

  const toggleModalBreak = () => {
    setModalBreak(state => !state)
  }

  const handleClose = () => {
    setOpenModalRoute(state => ({ ...state, open: false }))
  }
  const onOpenModal = (routeData: IRouteDashboard) => {
    setOpenModalRoute(state => ({ ...state, open: true, routeData }))
  }

  useEffect(() => {
    recoveryConference()
  }, [])

  return (
    <>
      <ContainerPage>
        <TitlePage>Dashboard</TitlePage>
        <Box className={classes.containerInfos}>
          <CardInfoToggle
            title="Previsão de término"
            totalOrder={dashboardData.total_orders - dashboardData.completed_orders}
            endTime={dashboardData.end_time}
          />

          <CardInfo
            title="Tempo médio bruto por pedido"
            timeData={Number(dashboardData.average_duration)}
          />
          <CardInfo
            title="Tempo médio líquido por pedido"
            timeData={Number(dashboardData.avg_time_first_to_last_item.average_time_seconds)}
          />

          <CardBreak quantity={breaks.length} action={toggleModalBreak} />

          <CardRoute onOpenModal={onOpenModal} />
        </Box>
        <Container className={classes.content}>
          <Box className={classes.summaryContainer}>
            <Summary
              totalItens={dashboardData?.total_items}
              totalOrders={total_orders}
              totalFrexcoOrders={total_frexco_orders}
              totalIfoodOrders={total_ifood_orders}
              completed={completed_orders}
              separationOrders={orders_in_progress}
              processedVolume={processed_volume}
              totalVolume={total_volume}
              lastUpdate={lastUpdate}
              pendentOrders={pendentOrders}
              tonsPerHour={tons_per_hour}
            />
          </Box>

          <Box className={classes.tracksContainer}>
            <LinearProgress
              style={{ height: '10px', borderRadius: '4px', opacity: loading ? 1 : 0 }}
            />
            <SeparatorList
              separators={data}
              averageDuration={dashboardData.average_duration}
              pendentOrders={pendentOrders}
              refreshDashboard={refreshDashboard}
              averageIdleTime={average_idle_time}
            />
          </Box>
        </Container>
      </ContainerPage>

      <ModalBreak open={modalBreak} handleClose={toggleModalBreak} breaks={breaks} />
      <ModalRoute
        data={openModalRoute.routeData}
        handleClose={handleClose}
        open={openModalRoute.open}
      />
    </>
  )
}

export default Dashboard
