import { Typography, makeStyles } from '@material-ui/core'
import { ModalConfirmLoad } from 'components/pages/Invoice'
import { useInvoiceOrder } from 'hooks/useInvoiceOrder'
import { useState } from 'react'
import { MdExpandMore, MdOutlineSearchOff } from 'react-icons/md'
import { Scrollbar } from 'shared'
import Pagination from 'shared/TableCustom/TablePagination'

import { Loading } from '../../styles'
import {
  Accordion,
  AccordionSummary,
  ButtonReleaseOrder,
  CardContainerOrdersStyled,
  ContainerAccordions,
  ContainerLabels,
  ContainerLabelsAccordion,
  ContainerScroll,
  Search,
} from './styles'
import { OrderDetailsResponse } from 'interfaces/IOrderDetails'

export const useStyles = makeStyles({
  root: {
    margin: '13rem auto',
    color: '#757575',
    fontSize: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
})

const CardContainerOrders = () => {
  const classes = useStyles()
  const { data, params, isLoading, onChange, count, search, searchDebaunce, pollingInvoice } =
    useInvoiceOrder()

  const [isOrderLoadConfirmed, setIsOrderLoadConfirmed] = useState({
    isOpen: false,
    order: null as any as OrderDetailsResponse,
  })

  const handleCloseOrderLoadConfirm = () => {
    setIsOrderLoadConfirmed({
      isOpen: false,
      order: null as any as OrderDetailsResponse,
    })
  }

  const handleOrderLoadRelease = (e: any, order: OrderDetailsResponse) => {
    e.stopPropagation()
    setIsOrderLoadConfirmed({
      isOpen: true,
      order,
    })
  }

  return (
    <CardContainerOrdersStyled>
      <Loading loading={isLoading} />
      <Search
        placeholder="N° do pedido ou da rota"
        onChange={e => {
          searchDebaunce(e.target.value)
        }}
        defaultValue={search}
      />
      {!isLoading && data.length === 0 && (
        <Typography className={classes.root}>
          <MdOutlineSearchOff size={50} />
          Nenhum pedido sem NF encontrado
        </Typography>
      )}

      {data.length > 0 && (
        <>
          <ContainerScroll>
            <ContainerAccordions>
              <ContainerLabels>
                <span>Rota</span>
                <span>N° Pedido</span>
                <span>Cliente</span>
              </ContainerLabels>
              {data.map(order => (
                <Accordion key={order.order_id}>
                  <ContainerLabelsAccordion>
                    <span>{order.route || 'Sem rota'}</span>
                    <span>{order.order_id}</span>
                    <span>{order.client_name_or_trade}</span>
                    <span>
                      <ButtonReleaseOrder
                        onClick={e => {
                          handleOrderLoadRelease(e, order)
                        }}
                      >
                        Liberar pedido
                      </ButtonReleaseOrder>
                    </span>
                  </ContainerLabelsAccordion>
                </Accordion>
              ))}
            </ContainerAccordions>
          </ContainerScroll>
          <Scrollbar>
            <Pagination
              page={params.page}
              count={count}
              onChange={(_, newPage: number) => onChange({ page: newPage })}
              rowsPerPage={params.pageSize}
              handleRowsPerPageChange={newPageSize => onChange({ pageSize: newPageSize, page: 1 })}
            />
          </Scrollbar>
        </>
      )}

      {isOrderLoadConfirmed.isOpen && (
        <ModalConfirmLoad
          open={isOrderLoadConfirmed.isOpen}
          pollingInvoice={pollingInvoice}
          handleClose={handleCloseOrderLoadConfirm}
          order={isOrderLoadConfirmed.order}
        />
      )}
    </CardContainerOrdersStyled>
  )
}

export default CardContainerOrders
