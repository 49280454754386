import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: '1600px',
    width: '100%',
    padding: 0,
    margin: '16px 0 0 0',
    display: 'grid',
    gridTemplateAreas: `
      'tracks summary'
      'orders summary'
    `,
    gridTemplateColumns: '75% 25%',
    [theme.breakpoints.between(0, 1200)]: {
      display: 'block',
    },
  },
  summaryContainer: {
    flex: 1,
    gridArea: 'summary',
    alignItems: 'flex-start',
    [theme.breakpoints.between(0, 1200)]: {
      flexDirection: 'column',
      marginTop: '1rem',
    },
  },
  tracksContainer: {
    flex: 1,
    gridArea: 'tracks',
    marginTop: '1rem',
    [theme.breakpoints.between(0, 1200)]: {
      gridArea: 'orders',
    },
  },
  containerInfos: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    alignItems: 'center',
    display: 'grid',
    gap: '0.625rem',
    [theme.breakpoints.between(490, 1050)]: {
      gridTemplateColumns: '1fr 1fr',
      marginTop: '1rem',
      paddingRight: '0',
    },
    [theme.breakpoints.between(490, 720)]: {
      gridTemplateColumns: '1fr 1fr',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.between(0, 490)]: {
      gridTemplateColumns: '1fr',
      flexDirection: 'column',
      alignItems: 'initial',
    },
  },
  containerOrders: {
    gridArea: 'orders',
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '2rem',
    [theme.breakpoints.between(0, 1050)]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.between(0, 1200)]: {
      gridArea: 'orders',
    },
  },
}))
