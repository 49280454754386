import {
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { ColorRoute } from 'components/pages/PackingList/ColorRoute'
import { useMemo, useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { BsCircleFill } from 'react-icons/bs'
import { MdExpandMore } from 'react-icons/md'
import { animated } from 'react-spring'
import { mapTagColor } from 'utils/mapTagColor'

import { IHandleEvent } from '../..'
import { AccordionStyled, ButtonPrinter, StrongHeader } from './styles'
import { IPackingList, IResponsePackingDict } from 'interfaces/IPackingList'

interface Props {
  item: IResponsePackingDict
  handleEvent: (param: IHandleEvent) => void
  first?: boolean
}
const Accordion = ({ item, handleEvent, first }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const readBoxes = useMemo(() => {
    return item.orders.reduce((acc, order) => {
      const boxes = Number(order?.adjusted_boxes_number_conveyor || order?.corrected_boxes_number || order.loading_boxes_number)
      return acc + boxes
    }, 0)
  }, [item])

  const getTotalOrderBoxes = (order: IPackingList) => {
    if (order.skipped) {
      return `${order.corrected_boxes_number} / ${order.corrected_boxes_number} (Foram corrigidas)`
    }
    if (order.adjusted_boxes_number) {
      return `${order.loading_boxes_number || 0} / ${order.adjusted_boxes_number
        } (Lidas / Ajustadas)`
    }
    if (order.boxes_by_conferencer) {
      return `${order.loading_boxes_number || 0} / ${order.boxes_by_conferencer
        } (Lidas / Corrigidas)`
    }
    if (order.conference_boxes_number === 0) {
      return 'Finalizado manualmente'
    }
    return `${order.loading_boxes_number || 0} / ${order.conference_boxes_number
      } (Lidas / Separadas)`
  }

  const refactTimeToDelivery = (time: string) => {
    const formattedTime = time?.split(':').slice(0, 2).join(':')
    return formattedTime
  }

  return (
    <>
      <animated.div>
        <AccordionStyled expanded={expanded} key={item.route}>
          <AccordionSummary
            expandIcon={
              <MdExpandMore
                onClick={() => {
                  setExpanded(state => !state)
                }}
              />
            }
          >
            <div>
              <ColorRoute colorRoute={mapTagColor(item.colorRoute)} />
            </div>
            <StrongHeader>
              <span>
                {!item.route && <>Sem rota / {item.colorRoute} </>}
                {!!item.route && (
                  <>
                    Rota {item.route} / {item.colorRoute}
                  </>
                )}
              </span>
            </StrongHeader>
            <StrongHeader>
              <span>{item.orders[0]?.order_delivery_details?.driver ?? 'Sem motorista'}</span>
            </StrongHeader>
            <StrongHeader>
              <span>
                {item.quantityOrders} {item.quantityOrders === 1 ? 'Pedido' : 'Pedidos'}
              </span>
            </StrongHeader>
            <StrongHeader>
              <span>
                {readBoxes} {readBoxes === 1 ? 'Caixa' : 'Caixas'}
              </span>
            </StrongHeader>
            <StrongHeader>
              <span>
                {item.status === 'pending' && (
                  <>
                    <BsCircleFill style={{ marginRight: '0.5rem' }} color="#FFC107" /> Pendente
                  </>
                )}
                {item.status === 'loaded' && (
                  <>
                    <BsCircleFill style={{ marginRight: '0.5rem' }} color="#2E75FF" /> Carregado
                  </>
                )}
              </span>
            </StrongHeader>
            <ButtonPrinter
              onClick={() => {
                handleEvent({ type: 'print', param: item.route })
              }}
              disabled={item.status === 'pending'}
            >
              <AiFillPrinter style={{ fontSize: '30px', color: '#618619' }} />
            </ButtonPrinter>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>N° Do Pedido</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Bairro</TableCell>
                  <TableCell>Endereço</TableCell>
                  <TableCell>NF</TableCell>
                  <TableCell>Horário</TableCell>
                  <TableCell>Caixas</TableCell>
                  <TableCell>Obs</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.orders.map(order => (
                  <TableRow key={order.id}>
                    <TableCell>{order.order_id}</TableCell>
                    <TableCell>{order.client_name_or_trade}</TableCell>
                    <TableCell>{order.order_delivery_details?.neighborhood}</TableCell>
                    <TableCell>
                      {order.order_delivery_details?.shipping_street_and_number}
                    </TableCell>
                    <TableCell>{order.order_delivery_details?.bill_of_sale_id}</TableCell>
                    <TableCell>
                      {refactTimeToDelivery(order.order_delivery_details?.scheduled_time_from)} -{' '}
                      {refactTimeToDelivery(order.order_delivery_details?.scheduled_time_to)}
                    </TableCell>
                    <TableCell>{getTotalOrderBoxes(order)}</TableCell>
                    <TableCell>{order.order_delivery_details?.observations}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </AccordionStyled>
      </animated.div>
    </>
  )
}

export default Accordion
