import { Box, SvgIcon, Typography } from '@material-ui/core'
import { ReactComponent as ClockOrder } from 'assets/icons/clock-order.svg'
import { transparentize } from 'polished'
import { dashboardCalculateTime } from 'utils'

import { useStyles } from './styles'

interface ICardInfo {
  title: string
  timeData: number
}

export const CardInfo = (props: ICardInfo) => {
  const classes = useStyles()
  const { timeData, title } = props
  const time = dashboardCalculateTime(timeData)

  return (
    <Box className={classes.root}>
      <span className={classes.containerIcon}>
        <SvgIcon
          component={ClockOrder}
          viewBox="0 0 35 35"
          style={{
            fontSize: '2rem',
            color: transparentize(0.7, '#6ACFD8'),
            width: '30px',
            height: '30px',
          }}
        />
      </span>

      <Box>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="subtitle2" className={classes.subtitle}>
          {time}
        </Typography>
      </Box>
    </Box>
  )
}
