import {
  Box,
  Checkbox,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { ContainerPage } from 'components'
import { ReturnArea } from 'components/pages/Config/'
import { v4 } from 'uuid'
import { useStyles } from './styles'
import { days, mapDays } from './mapDays'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import { Days, ISettingsRequest, settingsService } from 'services/settingsService'
import { usePopup } from 'hooks/usePopup'
import { useConfig } from 'hooks/useConfig/Index'

export const HighOrders = () => {
  const settings = useSelector<IState, ISettingsRequest>(state => state.settings)
  const { getSetting } = useConfig()
  const [highDays, setHighDays] = useState<Days[]>(settings.peakDays || [])
  const [loading, setLoading] = useState(false)
  const { addPopup } = usePopup()

  const classes = useStyles()

  const updatePeckDays = async (peakDays: Days[]) => {
    try {
      setLoading(true)
      await settingsService.updateSettings({ ...settings, peakDays })
      await getSetting()
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao atualizar configuração',
        description: error?.message,
      })
    }
    setLoading(false)
  }

  const onClickCheckbox = (day: Days) => {
    setHighDays(state => {
      if (state.includes(day)) {
        state = state.filter(item => item !== day)
        updatePeckDays(state)
        return state
      }
      state = [...state, day]
      updatePeckDays(state)
      return state
    })
  }

  useEffect(() => {
    setHighDays(settings.peakDays || [])
  }, [settings.peakDays])

  return (
    <ContainerPage>
      <ReturnArea title="Dias de alta" />

      <Box className={classes.boxAlign}>
        <Typography className={classes.titlePage}>Dias de alta</Typography>
      </Box>
      <Box className={classes.boxAlign}>
        <Typography className={classes.helpDescription}>Pra lembrar: </Typography>
      </Box>
      <Box className={classes.boxAlign}>
        <Typography className={classes.help}>
          ⬇️ Nos dias de baixa, que a operação começa depois das rotas, os pedidos de SP sairão
          todos primeiro, seguindo a ordenação padrão entre eles.
        </Typography>
      </Box>
      <Box className={classes.boxAlign}>
        <Typography className={classes.help}>
          ⬆️ Nos dias de alta, onde a operação começa antes das rotas, os pedidos seguirão a
          ordenação padrão.
        </Typography>
      </Box>
      <Box className={classes.boxAlign}>
        <Typography className={classes.help}>
          Ordenação padrão antes da rota: Prioridade ➡️ Macro ➡️ Número do pedido
        </Typography>
      </Box>
      <Box className={classes.boxAlign}>
        <Typography className={classes.help}>
          Ordenação padrão depois da rota: Prioridade ➡️ Rota ➡️ Número do pedido
        </Typography>
      </Box>
      {loading && <LinearProgress className={classes.loading} />}
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Dias</TableCell>
              <TableCell align="center">É dia de alta?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {days.map(day => {
              const key = v4()
              return (
                <TableRow key={key}>
                  <TableCell align="left">{mapDays(day.name)}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      checked={highDays.includes(day.name)}
                      disabled={day.disabled}
                      onChange={() => onClickCheckbox(day.name)}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ContainerPage>
  )
}
