import { Box, Typography } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import orderCanceledImg from 'assets/images/order-canceled.svg'
import { Button } from 'components'
import { ViewProps } from 'interfaces/IView'

import { useStyles } from './styles'

interface Props {
  getOrder: () => Promise<void>
  orderId: string | null
  clientName: string | null
  view: ViewProps
}

export const OrderResetOrCanceled = (props: Props) => {
  const { clientName, getOrder, orderId, view } = props
  const classes = useStyles()

  if (['reset', 'canceled'].includes(view)) {
    return (
      <Box className={classes.root}>
        <header className={classes.header}>
          <img src={orderCanceledImg} className={classes.imgCompleted} />
          <Typography component="strong" className={classes.title}>
            Pedido {view === 'reset' ? 'resetado' : 'cancelado'}
          </Typography>
        </header>

        <Typography component="strong" className={classes.title}>
          Pedido: <strong>{`#${orderId}`}</strong>
        </Typography>
        <Typography className={classes.subtitle}>{clientName}</Typography>

        <Button
          endIcon={<ArrowForwardIos />}
          onClick={getOrder}
          className={classes.nextOrder}
          variant="contained"
        >
          Próximo pedido
        </Button>
      </Box>
    )
  }

  return null
}
