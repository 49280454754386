import { enRules, routeConfig } from 'interfaces/IUser'
import LoadingRules from 'pages/Public/LoadingRules'
import { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { IState } from 'store'
import { IUser } from 'store/reducers/user/reducer'

type IPublicRoute = {
  component: ComponentType
}

export const PublicRoute = ({ component: RouteComponent }: IPublicRoute) => {
  const { isAuthenticated, userPermissions, isLoading } = useSelector<IState, IUser>(
    state => state.user,
  )
  const location = useLocation()
  const { painelRules } = routeConfig

  const canAccessPainel = painelRules.some(rule => userPermissions.includes(rule))

  if (isLoading) {
    return <LoadingRules />
  }
  if (
    isAuthenticated &&
    userPermissions.includes(enRules.SeparationReplenishmentResponse) &&
    !userPermissions.some(rule => [enRules.Tech].includes(rule))
  ) {
    return <Navigate to="/painel/reabastecimento" state={{ from: location }} replace />
  }

  if (isAuthenticated && canAccessPainel) {
    return <Navigate to="/painel/dashboard" state={{ from: location }} replace />
  }
  if (isAuthenticated && userPermissions.includes(enRules.SeparationCheck)) {
    return <Navigate to="/operacao/conferencia/inicio" state={{ from: location }} replace />
  }
  if (
    isAuthenticated &&
    userPermissions.length === 1 &&
    userPermissions.includes(enRules.SeparationFixedReader)
  ) {
    return (
      <Navigate to="/painel/conferencia-de-caixas/leitor-fixo" state={{ from: location }} replace />
    )
  }
  if (isAuthenticated) {
    return <Navigate to="/operacao/separacao/desempenho" state={{ from: location }} replace />
  }

  return <RouteComponent />
}
