import { usePopup } from 'hooks/usePopup'
import { OrderDetailsResponse } from 'interfaces/IOrderDetails'
import { IOnChange } from 'interfaces/IPagination'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { invoiceService } from 'services/invoiceService'
import { debaunce } from 'utils'

export const useInvoiceOrder = () => {
  const { addPopup } = usePopup()
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const controller = new AbortController()
  const [isLoading, setIsloading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [count, setCount] = useState(0)
  const [data, setData] = useState<OrderDetailsResponse[]>([])
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    search: searchParams.get('search') ?? '',
    ordersWithoutInvoice: true,
  })
  const searchDebaunce = debaunce({ fn: handleSearch, delay: 300 })
  const search = searchParams.get('search')

  function handleSearch(value: string) {
    setSearchParams(`search=${value}`)
    setParams(prevState => ({ ...prevState, search: value, page: 1 }))
  }

  const onChange = (newParams: IOnChange) => {
    setParams(prevState => ({ ...prevState, ...newParams }))
  }

  const pollingInvoice = async (isError = false, isRefresh = false) => {
    try {
      const { results, totalCount } = await invoiceService.getOrdersWithoutInvoice(
        params,
        controller.signal,
      )
      setCount(totalCount)
      setData(results)
    } catch {
      if (isError === false && controller.signal.aborted === false) {
        addPopup({
          type: 'error',
          title: 'Erro ao buscar pedidos sem NF',
        })
      }
    } finally {
      if (controller.signal.aborted === false && isRefresh === false) {
        timeout.current = setTimeout(() => pollingInvoice(true), 7000)
      }
      setIsloading(false)
    }
  }

  useEffect(() => {
    clearTimeout(timeout.current as any)
    pollingInvoice()

    return () => {
      controller.abort()
      clearTimeout(timeout.current as any)
    }
  }, [params])

  return {
    data,
    isLoading,
    searchDebaunce,
    search,
    pollingInvoice,
    params,
    onChange,
    count,
  }
}
