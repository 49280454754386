import { Container, Typography, Fab } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { LoadingSearch, FabMenu, OrderResetOrCanceled } from 'components'
import {
  ErrorFeedback,
  ModalPerformance,
  OperationCompleted,
  StartSeparation,
} from 'components/pages/Order'
import { useConferenceChuchu } from 'hooks/useConferenceChuchu'
import { ScreenState, useOrderSeparation } from 'hooks/useOrderSeparation'
import { IProduct } from 'interfaces/IProduct'
import { ReactNode, useMemo, useState, useEffect, useRef } from 'react'
import { SiGoogleanalytics } from 'react-icons/si'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Case, Switch } from 'shared'
import { IState } from 'store'
import { storeMap } from 'utils'

import { IDialogAlertBeginner, DialogAlertBeginner } from './DialogAlertBeginner'
import { DialogBoxCount } from './DialogBoxCount'
import { DialogBoxCountByStep, IDialogBoxCountByStep } from './DialogBoxCountByStep'
import { DialogBreak, IDialogBreak } from './DialogBreak'
import { DialogLoading, IDialogLoading } from './DialogLoading'
import { DialogPalletAlert, IDialogPalletAlert } from './DialogPalletAlert'
import { DialogScanTag, IDialogScanTag } from './DialogScanTag'
import { Header } from './Header'
import { Section } from './Section'
import { SeparationCompleted } from './SeparationCompleted'
import SeparetedProducts from './SeparetedProducts'
import { useStylesComponents, useStylesContainer } from './styles'

interface ContainerProps {
  children: NonNullable<ReactNode>
  screenState: ScreenState
}

const ContainerOrder = ({ children, screenState }: ContainerProps) => {
  const classes = useStylesContainer()

  return (
    <Container className={classes.root}>
      {screenState === 'viewGetOrder' && (
        <Link to="../../separacao/grupos" className={classes.back}>
          <KeyboardBackspaceIcon fontSize="medium" />
          Voltar
        </Link>
      )}
      {children}
    </Container>
  )
}

const OrderSeparation = () => {
  const dialogAlertBeginnerRef = useRef<IDialogAlertBeginner>(null)
  const dialogBoxCountByStepRef = useRef<IDialogBoxCountByStep>(null)
  const dialogLoadingRef = useRef<IDialogLoading>(null)
  const dialogPalletAlertRef = useRef<IDialogPalletAlert>(null)
  const dialogScanTagRef = useRef<IDialogScanTag>(null)
  const dialogBreakRef = useRef<IDialogBreak>(null)

  const { orderFastTrack } = useSelector<IState, IState>(state => state)

  const {
    screenState,
    steps,
    error,
    getOrderId,
    handleConfirmItem,
    handleConfirmOrder,
    resetOrder,
    openBoxCountToPrint,
    setOpenBoxCountToPrint,
  } = useOrderSeparation({
    openPallet: () => dialogPalletAlertRef.current?.onOpen(),
    openScannerTag: () => dialogScanTagRef.current?.onOpen(),
    openAlertBeginner: () => dialogAlertBeginnerRef.current?.onOpen(),
    openBoxCountByStep: (data: any) => dialogBoxCountByStepRef.current?.onOpen(data),
    closeBoxCountByStep: () => dialogBoxCountByStepRef.current?.onClose(),
  })

  const { recoveryConference } = useConferenceChuchu()

  const classes = useStylesComponents({ store: storeMap(orderFastTrack.order?.store_id) })
  const [modalPerformance, setModalPerformance] = useState(true)
  const [switchSepareted, setSwitchSepareted] = useState<'separeted' | 'breaches'>('separeted')

  const { productSeparated, currentStep, nextStep } = useMemo(() => {
    const isPending = (item: IProduct) => item.status === 1

    const stepsFiltered = steps
      .filter(({ items }) => items.some(isPending))
      .map(({ step, items }) => ({ step, items: items.filter(isPending) }))

    const currentStep = stepsFiltered[0]

    const nextStep = stepsFiltered[1]

    const productSeparated = steps.reduce((items, step) => {
      return [...step.items.filter(item => item.status !== 1), ...items]
    }, [] as IProduct[])

    return { stepsFiltered, productSeparated, currentStep, nextStep }
  }, [steps])

  useEffect(() => {
    recoveryConference()
  }, [])

  const clientName =
    orderFastTrack.order?.client_data?.client_type === 'PF'
      ? orderFastTrack.order?.client_data?.client_name
      : orderFastTrack.order?.client_data?.client_trade

  return (
    <>
      <ContainerOrder screenState={screenState}>
        <Switch test={screenState}>
          <Case value="viewOrderCompleted">
            <SeparationCompleted
              getOrderId={getOrderId}
              order={orderFastTrack.previousOrder}
              isConferenceGroup={false}
            />
          </Case>
          <Case value="viewOrderSeparation">
            <Header
              view="separation"
              order={orderFastTrack.order}
              begin={orderFastTrack.work?.begin as Date}
            />

            <Container className={classes.container}>
              {currentStep && (
                <Section
                  nextStep={nextStep}
                  orderId={orderFastTrack.order?.order_id}
                  handleConfirmItem={handleConfirmItem}
                  handleDialogBreach={dialogBreakRef.current?.onOpen}
                  resetOrder={resetOrder}
                  {...currentStep}
                />
              )}

              {productSeparated.length !== 0 && (
                <SeparetedProducts
                  productSeparated={productSeparated}
                  setSwitchSepareted={setSwitchSepareted}
                  switchSepareted={switchSepareted}
                />
              )}
            </Container>
          </Case>
          <Case value="viewLoadingOrderPending">
            <LoadingSearch textLoading="Carregando" />
          </Case>
          <Case value="viewLoadingGetOrder">
            <LoadingSearch textLoading="Procurando" />
          </Case>
          <Case value="viewGetOrder">
            <StartSeparation getOrderId={getOrderId} />
          </Case>
          <Case value="viewOrderReset">
            <OrderResetOrCanceled
              view="reset"
              getOrder={getOrderId}
              clientName={clientName}
              orderId={orderFastTrack.order?.order_id}
            />
          </Case>
          <Case value="viewOrderCanceled">
            <OrderResetOrCanceled
              view="canceled"
              getOrder={getOrderId}
              clientName={clientName}
              orderId={orderFastTrack.order?.order_id}
            />
          </Case>
          <Case value="viewError">
            <ErrorFeedback {...error} />
          </Case>
          <Case value="viewAllOrderCompleted">
            <ModalPerformance
              isOpen={modalPerformance}
              handleClose={() => setModalPerformance(false)}
            />

            <Fab
              className={classes.printerBtn}
              onClick={() => setModalPerformance(true)}
              variant="extended"
              size="medium"
            >
              <SiGoogleanalytics color="#6ACFD8" size={20} style={{ marginRight: '0.5rem' }} />
              <Typography style={{ textTransform: 'none', fontWeight: 500 }}>Desempenho</Typography>
            </Fab>

            <OperationCompleted getOrderId={getOrderId} />
          </Case>

          <Case default>
            <h1>default</h1>
          </Case>
        </Switch>
      </ContainerOrder>

      <DialogAlertBeginner ref={dialogAlertBeginnerRef} />

      <DialogBoxCountByStep ref={dialogBoxCountByStepRef} />

      {/* Modal para o separador digitar a quantidade de caixas do pedido e imprimir as etiquetas */}
      <DialogBoxCount
        open={openBoxCountToPrint}
        handleConfirmOrder={handleConfirmOrder}
        onClose={() => setOpenBoxCountToPrint(false)}
        openModalScanner={() => dialogScanTagRef.current?.onOpen()}
        closeModalLoading={() => dialogLoadingRef.current?.onClose()}
        openModalLoading={() => dialogLoadingRef.current?.onOpen()}
      />

      {/* Modal para o separador scanear uma etiqueta impressa */}
      <DialogScanTag
        ref={dialogScanTagRef}
        handleConfirmOrder={handleConfirmOrder}
        closeModalLoading={() => dialogLoadingRef.current?.onClose()}
        openModalLoading={() => dialogLoadingRef.current?.onOpen()}
      />

      <DialogLoading ref={dialogLoadingRef} />

      <DialogBreak handleConfirmItem={handleConfirmItem} ref={dialogBreakRef} />

      <DialogPalletAlert ref={dialogPalletAlertRef} />

      <FabMenu item={currentStep?.items?.[0]} />
    </>
  )
}

export default OrderSeparation
