import {
  ISeparationGroupConfig,
  ISeparationGroupConfigRequest,
} from 'interfaces/ISeparationGroupService'

import { api as apiService, ApiService } from './api'

export type Days =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'

export interface ISettings {
  separations: number
  number_of_separators: number
  modified_by: number
  id: number
  created: Date
  goal_separate: number
  peak_days: Days[]
  token: string
  pallet_limit_weight: number
  pallet_limit_wholesale_box: number
  pre_picking_validity_check: boolean
}

export interface ISettingsRequest
  extends Omit<
    ISettings,
    | 'modified_by'
    | 'id'
    | 'created'
    | 'peak_days'
    | 'pallet_limit_weight'
    | 'pallet_limit_wholesale_box'
  > {
  numberOfSeparators: number
  goalSeparate: number
  peakDays: Days[]
  separationGroups: ISeparationGroupConfig[]
  palletLimitWeight: number
  palletLimitWholesaleBox: number
  prePickingValidityCheck: boolean
}

class SettingsService {
  constructor(private readonly api: ApiService) {}

  async getSettings(): Promise<ISettings> {
    return await this.api.get(`${carambolaUrl}/separation-settings/`)
  }

  async updateSettings(data: Partial<ISettingsRequest>): Promise<ISeparationGroupConfig> {
    return await this.api.post(`${carambolaUrl}/separation-settings/`, data)
  }

  async getSeparationGroup(): Promise<ISeparationGroupConfig[]> {
    return await this.api.get(`${carambolaUrl}/separation-settings/separation-group`)
  }

  async postSeparationGroup(data: ISeparationGroupConfigRequest): Promise<any> {
    return await this.api.post(`${carambolaUrl}/separation-settings/separation-group`, data)
  }

  async updateSeparationGroup(data: ISeparationGroupConfigRequest): Promise<any> {
    return await this.api.put(
      `${carambolaUrl}/separation-settings/separation-group/${data.separationGroup}`,
      data,
    )
  }

  async deleteSeparationGroup(data: ISeparationGroupConfigRequest): Promise<void> {
    return await this.api.delete(
      `${carambolaUrl}/separation-settings/separation-group/${data.separationGroup}`,
    )
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const settingsService = new SettingsService(apiService)
