import { Box, Typography, Button as ButtonMui } from '@material-ui/core'
import { Button } from 'components'
import { usePopup } from 'hooks/usePopup'
import { useState } from 'react'
import { separationOrderService } from 'services/separationOrderService'

import {
  ButtonCancel,
  ButtonConfirm,
  Container,
  ContainerButtons,
  InfoCostumer,
  Modal,
  TextConfirmRelease,
  Title,
} from './styles'
import { Runouts } from 'interfaces/IBreakOrder'
import { Loading } from 'pages/Admin/Breaks/styles'
import { IGetItensToReposition, separationItemService } from 'services/separationItemService'

interface IModalConfirmOrderLoad {
  open: boolean
  handleClose: () => void
  item: Runouts
  order: IGetItensToReposition
  refresh: () => void
}

const ModalReposition = ({ handleClose, item, order, open, refresh }: IModalConfirmOrderLoad) => {
  const { addPopup } = usePopup()
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await separationItemService.repositionItemWithoutProduct(item.id)
      addPopup({
        type: 'success',
        title: 'Quebra de item retirada com sucesso',
      })
      handleClose()
      refresh()
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Ocorreu um erro ao retirar a quebra',
        description: error?.message || error,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal open={open}>
      <Container>
        <Loading loading={isLoading} />
        <Title>Retirar quebra</Title>
        <InfoCostumer>
          <p>
            Item: <strong>{item.name}</strong>
          </p>
          <p>
            N° Pedido: <strong>#{order.order_id}</strong>
          </p>
          <p>
            Cliente: <strong>{order.client_name}</strong>
          </p>
        </InfoCostumer>
        <TextConfirmRelease>Retirar a quebra desse item?</TextConfirmRelease>
        <ContainerButtons>
          <ButtonCancel onClick={handleClose} disabled={isLoading}>
            Cancelar
          </ButtonCancel>
          <ButtonConfirm onClick={handleConfirm} disabled={isLoading}>
            Retirar
          </ButtonConfirm>
        </ContainerButtons>
      </Container>
    </Modal>
  )
}

export default ModalReposition
