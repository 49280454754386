import { Tab } from '@material-ui/core'
import { ContainerPage, TitlePage } from 'components'
import { ChangeEvent, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import CardContainerOrders from './components/CardContainerOrders'
import CardHistory from './components/CardHistory'
import { ContainerHeader, TabContainer } from './styles'

const Invoices = () => {
  const [tab, setTab] = useState(0)

  const onChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  return (
    <>
      <ContainerPage>
        <ContainerHeader component="header">
          <TitlePage>Nota Fiscal</TitlePage>
        </ContainerHeader>
        <TabContainer value={tab} onChange={onChangeTab} centered tab={tab}>
          <Tab label="Pedidos sem nota fiscal" />
          <Tab label="Liberados sem nota fiscal" />
        </TabContainer>
        {tab === 0 && <CardContainerOrders />}
        {tab === 1 && <CardHistory />}
      </ContainerPage>
    </>
  )
}

export default Invoices
