import { Avatar, Box, Typography } from '@material-ui/core'
import { IRanking } from 'interfaces/IRanking'
import { stringAvatar, stringToColor } from 'utils'

import { useStyles } from './styles'

interface IProps {
  position?: number
  separator: IRanking
}

const calculateSeconds = (seconds: string) => {
  const msec = Number(seconds)

  const time = {
    days: String(Math.floor(msec / (60 * 60 * 24))),
    hours: String(Math.floor((msec / (60 * 60)) % 24)).padStart(2, '0'),
    minutes: String(Math.floor((msec / 60) % 60)).padStart(2, '0'),
    seconds: String(Math.floor(msec % 60)).padStart(2, '0'),
  }

  return `${time.hours}:${time.minutes}:${time.seconds}`
}

export const Classified = ({ separator, position }: IProps) => {
  const { idle_time, total_orders, username, total_items } = separator
  const classes = useStyles()

  return total_orders ? (
    <Box className={classes.boxAlign}>
      <Box className={classes.rankingInfo}>
        <Typography className={classes.positionText}>{position}°</Typography>

        <Avatar className={classes.image} style={{ background: stringToColor(username) }}>
          {stringAvatar(username)}
        </Avatar>

        <Typography className={classes.nameText}>{username}</Typography>
      </Box>

      <Box className={classes.orderInfo}>
        <Typography className={classes.itemsText}>
          Itens separados: <strong>{total_items}</strong>
        </Typography>

        <Typography className={classes.ordersText}>
          Pedidos: <strong>{total_orders}</strong>
        </Typography>

        <Typography className={classes.timeText}>
          Tempo parado:{' '}
          {idle_time ? <strong>{calculateSeconds(idle_time)}</strong> : <strong>00:00:00</strong>}
        </Typography>
      </Box>
    </Box>
  ) : null
}
