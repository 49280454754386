import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@material-ui/core'
import { usePopup } from 'hooks/usePopup'
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState, memo } from 'react'
import { Modal } from 'shared'
import { mapMessageError } from 'utils/mapMessageError'

import { orderService } from '../../../services/orderService'

const LabelStyle = styled(Typography)({
  color: '#353535',
  textAlign: 'center',
  userSelect: 'none',
  fontSize: '1.1rem',
  '& span': {
    display: 'block',
    color: '#757575',
    fontSize: '0.95rem',
  },
})

const TitleStyle = styled(Typography)({
  color: '#353535',
  fontSize: '1.125rem',
  fontWeight: 500,
  marginBottom: '0.5rem',
})

const ButtonStyle = styled(Button)({
  padding: '0.8rem 2rem',
  margin: '1.5rem auto 0',
  marginTop: 'auto',
  textTransform: 'initial',
})

type Config = 'priority' | 'critical' | 'separated' | 'reset' | 'directedGroup' | ''

export interface IEditMultiple {
  toggleOpen: () => void
}

interface Props {
  refresh: () => void
  orders: string[]
  cleanOrders: () => void
}

export const Component: ForwardRefRenderFunction<IEditMultiple, Props> = (
  { refresh, orders, cleanOrders },
  ref,
) => {
  const { addPopup } = usePopup()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [separationGroup, setSeparationGroup] = useState<Number | undefined>()
  const [isTypeError, setIsTypeError] = useState({
    isCanceled: false,
    isPending: false,
  })
  const [configSelect, setConfigSelect] = useState<Config>('')

  const handleClose = () => {
    setIsOpen(false)
    setConfigSelect('')
    handleChangeStateStatus()
  }

  const handleChangeStateStatus = (type?: string) => {
    if (type === 'pending') {
      return setIsTypeError({ isPending: true, isCanceled: false })
    }

    if (type === 'canceled') {
      return setIsTypeError({ isPending: false, isCanceled: true })
    }

    setIsTypeError({
      isPending: false,
      isCanceled: false,
    })
  }

  const toggleOpen = () => {
    setIsOpen(state => !state)
  }

  useImperativeHandle(ref, () => ({ toggleOpen }))

  const handleChangeConfig = async () => {
    try {
      setIsLoading(true)
      if (configSelect === 'directedGroup') {
        const response = await orderService.changeConfig({
          [configSelect]: separationGroup,
          ordersId: orders,
        })
        addPopup({
          type: 'success',
          title: response.message,
        })
      } else {
        const response = await orderService.changeConfig({
          [configSelect]: true,
          ordersId: orders,
        })
        addPopup({
          type: 'success',
          title: response.message,
        })
      }
      refresh()
      handleClose()
    } catch (error: any) {
      const messageError = error?.message

      if (messageError.includes('iniciado')) {
        return handleChangeStateStatus('pending')
      }

      if (messageError.includes('canceled')) {
        return handleChangeStateStatus('canceled')
      }
      addPopup({
        type: 'error',
        title: mapMessageError(error?.message),
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      open={isOpen}
      handleClose={() => {
        toggleOpen()
        handleChangeStateStatus()
      }}
      title="Ajustes"
      style={{ maxHeight: '30.25rem', height: '100%' }}
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <TitleStyle>
          Quantidade de pedidos selecionados: <strong>{orders.length}</strong>
        </TitleStyle>
      </Box>

      <Box
        component="form"
        display="flex"
        alignItems="space-between"
        justifyContent="space-between"
        flexDirection="column"
        pt="1rem"
        height="calc(100% - 45px)"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="grouped-select">Selecione a configuração</InputLabel>
          <Select
            id="grouped-select"
            value={configSelect}
            onChange={e => {
              const value = e.target.value
              if (value) {
                setConfigSelect(value as Config)
              }
            }}
            label="Selecione a configuração"
          >
            <ListSubheader>O que você quer fazer?</ListSubheader>
            <MenuItem value="priority">Priorizar pedidos</MenuItem>
            <MenuItem value="critical">Sinalizar como críticos</MenuItem>
            <MenuItem value="directedGroup">Direcionar Pedidos</MenuItem>
            <MenuItem value="separated">Finalizar pedidos</MenuItem>
            <MenuItem value="reset">Resetar pedidos</MenuItem>
          </Select>
        </FormControl>

        {configSelect === 'priority' && (
          <Box mt="2rem" width="100%">
            <LabelStyle style={{ marginTop: '2rem' }}>
              Priorizar pedidos
              <span>Serão separados pelas próximas pessoas que solicitarem um novo pedido.</span>
            </LabelStyle>
          </Box>
        )}

        {configSelect === 'critical' && (
          <Box mt="2rem" width="100%">
            <LabelStyle style={{ marginTop: '2rem' }}>
              Pedidos críticos
              <span>Serão marcados como críticos para a separação.</span>
            </LabelStyle>
          </Box>
        )}

        {configSelect === 'directedGroup' && (
          <Box mt="2rem" width="100%">
            <LabelStyle style={{ marginTop: '2rem' }}>
              Direcionar pedidos
              <span>Digite abaixo o n° do grupo que deseja direcionar esses pedidos:</span>
              <input
                type="number"
                placeholder="N° do grupo"
                onChange={e => {
                  const number = parseInt(e.target.value)
                  if (number > 0) {
                    setSeparationGroup(number)
                  } else {
                    setSeparationGroup(undefined)
                  }
                }}
                style={{
                  marginTop: '1rem',
                  padding: '0.5rem',
                  borderRadius: '0.5rem',
                  border: '1px solid #ccc',
                }}
                min={1}
              />
            </LabelStyle>
          </Box>
        )}

        {configSelect === 'separated' && (
          <>
            <LabelStyle style={{ marginTop: '2rem' }}>
              Finalizar separações
              <span>Serão retirados da lista de separação e marcado como separado.</span>
            </LabelStyle>
          </>
        )}

        {configSelect === 'reset' && (
          <>
            <LabelStyle style={{ marginTop: '2rem' }}>
              Resetar separações
              <span>Pedidos voltam às configurações iniciais</span>
            </LabelStyle>

            {isTypeError.isCanceled ? (
              <LabelStyle
                style={{
                  marginTop: '2.2rem',
                  color: '#e63946',
                  fontWeight: 500,
                }}
              >
                Este pedido foi cancelado
                <span>
                  Retorne à tela de pedidos e selecione apenas pedidos que não foram cancelados
                </span>
              </LabelStyle>
            ) : null}

            {isTypeError.isPending ? (
              <LabelStyle
                style={{
                  marginTop: '2.2rem',
                  color: '#e63946',
                  fontWeight: 500,
                }}
              >
                Este pedido está pendente
                <span>
                  Retorne à tela de pedidos e selecione apenas pedidos que não estão pendentes
                </span>
              </LabelStyle>
            ) : null}
          </>
        )}

        {configSelect === '' ? (
          <ButtonStyle variant="contained" color="secondary" onClick={handleClose}>
            Fechar
          </ButtonStyle>
        ) : (
          <ButtonStyle
            disabled={isLoading || (configSelect === 'directedGroup' && !separationGroup)}
            variant="contained"
            color="secondary"
            onClick={handleChangeConfig}
          >
            {isLoading ? 'Alterando, aguarde...' : 'Salvar e fechar'}
          </ButtonStyle>
        )}
      </Box>
    </Modal>
  )
}

export const EditMultiple = memo(forwardRef(Component))
