import { Box, styled, Accordion, Button, Theme } from '@material-ui/core'

export const ButtonPrinter = styled(Button)(({ theme }: { theme: Theme }) => ({
  height: '2.5rem',
  color: '#618619',
  borderRadius: 'calc(2.5rem /2)',
  fontSize: '1rem',
  fontWeight: 400,
  marginRight: '0.5rem',
  width: '80px',
  '&:disabled': {
    filter: 'grayScale(1)',
  },
  '& svg': {
    fontSize: '19.5px',
    marginRight: '0.5rem',
  },
  [theme.breakpoints.down(900)]: {
    width: '110px',
    fontSize: '0.8rem',
    '& svg': {
      fontSize: '13px',
    },
  },
}))

export const AccordionStyled = styled(Accordion)({
  minHeight: '72px',
  '& .MuiAccordionSummary-root': {
    minHeight: '100%',
    paddingLeft: '0',
    width: '100%',
    '& .MuiAccordionSummary-content': {
      display: 'grid',
      gridTemplateColumns: '30px 1fr 1fr 1fr 1fr 1fr 1fr',
      width: '100%',
      alignItems: 'center',
      margin: 0,
      height: '100%',
    },
  },
})

export const BoxInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: '0.5rem',
  '& > strong': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
    minHeight: '72px',
    lineHeight: '36px',
    justifyContent: 'center',
    textAlign: 'center',
    width: '200px',
    color: '#5F5E69',
    fontWeight: 400,
    [theme.breakpoints.down(1200)]: {
      width: '150px',
    },
    [theme.breakpoints.down(900)]: {
      width: '140px',
    },
  },
}))

export const SpanHeader = styled('span')({
  position: 'absolute',
  top: '-50px',
  fontWeight: 500,
})

export const StrongHeader = styled('strong')({
  fontSize: '0.9rem',
  color: '#353535',
  fontWeight: 500,
})
