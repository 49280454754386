import { Container, styled, Typography } from '@material-ui/core'
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react'
import { Modal } from 'shared'

import { IOrdersInfo } from '..'

import { ITypeError } from '../CardError'

export interface IWarningModal {
  handleChangeOrder: (order: IDataWarningModal, error: ITypeError) => void
  isOpen: boolean
}

export interface IDataWarningModal {
  status: string
  client_type: string
  boxesAhead: number
  order: {
    order_id: string
  }
}

const Root = styled(Container)({
  padding: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > img': {
    margin: '1.25rem auto 1rem',
  },
  width: '100%',
})

const SubTitle = styled(Typography)({
  color: '#353535',
  fontWeight: 500,
})

export const Component: ForwardRefRenderFunction<IWarningModal> = (_, ref) => {
  const [data, setData] = useState<IDataWarningModal>({} as IDataWarningModal)
  const [error, setError] = useState<ITypeError | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
    setError(null)
    setData({} as IDataWarningModal)
  }

  const handleChangeOrder = (order: IDataWarningModal, error: ITypeError) => {
    setData(order)
    setIsOpen(true)
    setError(error)
  }

  useImperativeHandle(ref, () => ({ handleChangeOrder, isOpen }))

  return (
    <Modal
      open={isOpen}
      handleClose={handleClose}
      title={`O pedido #${data?.order?.order_id} foi ${error === 'canceled' ? 'cancelado' : 'reagendado'}`}
      backdropClick={false}
    >
      <Root maxWidth="md">
        {!!data?.boxesAhead && (
          <SubTitle>
            A caixa do pedido está {data?.boxesAhead} caixa{data?.boxesAhead > 1 ? 's' : ''} à
            frente
          </SubTitle>
        )}
        <SubTitle>
          Remova as caixas desse pedido da esteira.
        </SubTitle>
      </Root>
    </Modal>
  )
}

export const WarningModal = forwardRef(Component)
