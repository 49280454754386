import { styled, Box } from '@material-ui/core'
import IconSadApple from 'assets/images/sadAppleRed.svg'
import IconTag from 'assets/images/tag.svg'
import IconBoxes from 'assets/images/threeBoxes.svg'
import IconWrongRoute from 'assets/images/wrongRoute.svg'

export interface ITypeErrors {
  typeError?: ITypeError | null
  missingBoxes?: number
}

export type ITypeError = 'canceled' | 'aboveQuantity' | 'scannedTag' | 'wrongRoute' | 'rescheduled'

const Card = styled(Box)(({ theme }) => ({
  width: '100%',
  border: '1px solid #EB001B',
  borderRadius: '8px',
  background: '#F9E2E5',
  padding: '32px',
  marginTop: '10px',
  '& > img': {
    display: 'block',
    color: '#EB001B',
    margin: '0 auto 16px auto',
    [theme.breakpoints.down(450)]: {
      maxWidth: '80%',
    },
  },
  '& > strong': {
    fontSize: '1.5rem',
    color: '#EB001B',
    textAlign: 'center',
    display: 'block',
    fontWeight: 500,
  },
}))

const CardError = ({ typeError }: ITypeErrors) => {
  const children =
    (typeError &&
      {
        rescheduled: (
          <Card>
            <img src={IconSadApple} />
            <strong>Esse pedido foi reagendado</strong>
          </Card>
        ),
        canceled: (
          <Card>
            <img src={IconSadApple} />
            <strong>Esse pedido foi cancelado</strong>
          </Card>
        ),
        aboveQuantity: (
          <Card>
            <img src={IconBoxes} />
            <strong>A quantidade de caixas lidas é maior do que a informada</strong>
          </Card>
        ),
        scannedTag: (
          <Card>
            <img src={IconTag} />
            <strong>Essa etiqueta já foi lida</strong>
          </Card>
        ),
        wrongRoute: (
          <Card>
            <img src={IconWrongRoute} />
            <strong>Essa caixas não pertence à rota atual</strong>
          </Card>
        ),
      }[typeError]) ||
    null

  return children
}

export default CardError
