import { usePopup } from 'hooks/usePopup'
import { IRanking } from 'interfaces/IRanking'
import { useEffect, useRef, useState } from 'react'
import { rankingService } from 'services/rankingService'

let controllerWeekly: AbortController
let controllerDaily: AbortController

export const useRanking = () => {
  const { addPopup } = usePopup()
  const [dailyRanking, setDailyRanking] = useState({
    ranking: [] as IRanking[],
    initialDate: '',
    finalDate: '',
  })
  const [weeklyRanking, setWeeklyRanking] = useState({
    ranking: [] as IRanking[],
    initialDate: '',
    finalDate: '',
  })

  const timeoutDaily = useRef<ReturnType<typeof setTimeout>>()
  const timeoutWeekly = useRef<ReturnType<typeof setTimeout>>()
  const [dailyLoading, setDailyLoading] = useState(false)
  const [weeklyLoading, setWeeklyLoading] = useState(false)

  const getDailyRanking = async (isError = false) => {
    try {
      setDailyLoading(true)
      controllerDaily = new AbortController()
      const responseDaily = await rankingService.getDailyRanking(controllerDaily)

      setDailyRanking({
        initialDate: responseDaily.initial_date,
        finalDate: responseDaily.final_date,
        ranking: responseDaily.ranking,
      })
      setDailyLoading(false)
    } catch {
      if (!isError && !controllerDaily.signal.aborted) {
        addPopup({
          type: 'error',
          title: 'Erro ao receber ranking diário',
        })
      }
    } finally {
      setDailyLoading(false)
      if (!controllerDaily.signal.aborted) {
        timeoutDaily.current = setTimeout(() => getDailyRanking(true), 300000)
      }
    }
  }

  const getWeeklyRanking = async (isError = false) => {
    try {
      setWeeklyLoading(true)
      controllerWeekly = new AbortController()
      const responseWeekly = await rankingService.getWeeklyRanking(controllerWeekly)

      setWeeklyRanking({
        initialDate: responseWeekly.initial_date,
        finalDate: responseWeekly.final_date,
        ranking: responseWeekly.ranking,
      })
      setWeeklyLoading(false)
    } catch {
      if (!isError && !controllerWeekly.signal.aborted) {
        addPopup({
          type: 'error',
          title: 'Erro ao receber ranking semanal',
        })
      }
    } finally {
      setWeeklyLoading(false)
      if (!controllerWeekly.signal.aborted) {
        timeoutWeekly.current = setTimeout(() => getWeeklyRanking(true), 300000)
      }
    }
  }

  useEffect(() => {
    getDailyRanking()
    getWeeklyRanking()
    return () => {
      controllerDaily?.abort()
      controllerWeekly?.abort()
      // eslint-disable-next-line no-undef
      clearTimeout(timeoutDaily.current as NodeJS.Timeout)
      // eslint-disable-next-line no-undef
      clearTimeout(timeoutWeekly.current as NodeJS.Timeout)
    }
  }, [])

  return {
    dailyRanking,
    weeklyRanking,
    dailyLoading,
    weeklyLoading,
  }
}
